
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom'
import { React, useState , useEffect } from 'react'
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { Client , Account } from "appwrite";
import {useNumber} from './Context' 

const Container = tw(ContainerBase)`min-h-screen bg-red-200 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a`flex items-center`;
const LogoImage = tw.img`h-12 mr-2`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;

const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-white text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { updateUser } = useNumber();
  const [userState, setUserState] = useState([]);
  const navigate = useNavigate();
  const client = new Client()
    .setEndpoint('https://cloud.appwrite.io/v1') // Your API Endpoint
    .setProject('661d51c7e4d47fa7d45d');                 // Your project ID

  const account = new Account(client);


  const handleLogin = async () => {
    try {
      const response = await account.createEmailPasswordSession(email, password);
      console.log("User logged in:", response);
      setUserState(response.current)
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

useEffect (() => {
  const fetchUser = async () => {
    try {
      const result = await account.getSession("current");
      console.log(result.current);
      setUserState(result)
    } catch (error) {
      throw error
    }
  }
  fetchUser()
}, [])


  

updateUser(userState);


  const login = {
    logoLinkUrl: "#",
    illustrationImageSrc: "images/Untitlasaed-4.png",
    headingText: "Sign In To Casant Admin",
    socialButtons: [
      {
        iconImageSrc: googleIconImageSrc,
        text: "Sign In With Google",
        url: "https://google.com"
      },
      {
        iconImageSrc: twitterIconImageSrc,
        text: "Sign In With Twitter",
        url: "https://twitter.com"
      }
    ],
    submitButtonText: "Sign In",
    SubmitButtonIcon: LoginIcon,
    forgotPasswordUrl: "#",
    signupUrl: "#",
  }

  const deleteSessions = async () => {
    const promise = await account.deleteSessions();
    localStorage.clear();
    if (promise) {
      alert("deleted session");
    }
  }
  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={login.logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{login.headingText}</Heading>


              <Input type="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
              <Input type="password" autoComplete="current-password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
              <SubmitButton type="submit" onClick={handleLogin}>
                <span className="text">{login.submitButtonText}</span>
              </SubmitButton>

              <p tw="mt-6 text-xs text-gray-600 text-center">
                <a tw="border-b border-gray-500 border-dotted">
                  Hey Creator! Login With Casant Account
                </a>
              </p>
              <p tw="mt-8 text-sm text-gray-600 text-center">
                Dont have an account?{" "}
                <a onClick={deleteSessions}  tw="border-b border-gray-500 border-dotted cursor-pointer">
                  Ask Samuel!
                </a>
              </p>

            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={login.illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  )
}

export default Login

